
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmPriority from '@/components/shared/TmPriority.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmAssignee from '@/components/shared/TmAssignee.vue'
import { tasksColumnsMock } from '@/definitions/tasks/data'
import type { KanbanColumnType } from '@/definitions/shared/kanban/types'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import { formatDate } from '@/services/dateTimeService'
import TasksActions from '@/components/pages/tasks/index/TasksActions.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TasksActions,
    TmEllipsis,
    TmAssignee,
    TmPriority,
    TmStatus,
    TmTable,
  },
  setup() {
    const { openModal } = useModals()

    const getStatus = (column: string) => {
      return tasksColumnsMock.find((e: KanbanColumnType) => e.name === column)!.status
    }
    const getDate = (date: string) => {
      return Date.parse(date) ? formatDate(new Date(date)) : date
    }

    return {
      getDate,
      getStatus,
      formatDate,
      openModal,
    }
  },
})
