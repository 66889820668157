import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_toggle_buttons = _resolveComponent("tm-toggle-buttons")!
  const _component_dropdown_filter_assignee = _resolveComponent("dropdown-filter-assignee")!
  const _component_dropdown_filter_priority = _resolveComponent("dropdown-filter-priority")!
  const _component_dropdown_filter_radio = _resolveComponent("dropdown-filter-radio")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, {
    search: _ctx.search,
    "onUpdate:search": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
    "search-placeholder": "Search tasks",
    "show-filters": ""
  }, {
    "filter-line-left-selected": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "Delete",
        icon: "delete",
        onClick: _ctx.openDeleteTasks
      }, null, 8, ["onClick"])
    ]),
    "filter-line-right-prepend": _withCtx(() => [
      _createVNode(_component_tm_toggle_buttons, { size: "large" }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_button, {
            class: _normalizeClass(_ctx.$route.name === 'base.tasks.kanban' ? '_active' : ''),
            "icon-only": "",
            size: "large",
            "no-border": "",
            flat: "",
            to: { name: 'base.tasks.kanban' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_icon, {
                name: "tmi-kanban",
                class: "neutral--text"
              })
            ]),
            _: 1
          }, 8, ["class", "to"]),
          _createVNode(_component_tm_button, {
            class: _normalizeClass(_ctx.$route.name === 'base.tasks.list' ? '_active' : ''),
            "icon-only": "",
            size: "large",
            "no-border": "",
            flat: "",
            to: { name: 'base.tasks.list' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_icon, {
                class: "neutral--text",
                name: "format_list_bulleted"
              })
            ]),
            _: 1
          }, 8, ["class", "to"])
        ]),
        _: 1
      })
    ]),
    "filter-line-left-default": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "New task",
        icon: "add",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal('tasks')))
      })
    ]),
    "filter-line-details": _withCtx(() => [
      _createVNode(_component_dropdown_filter_assignee, {
        options: _ctx.assigneeOptions,
        "search-placeholder": "Search users"
      }, null, 8, ["options"]),
      _createVNode(_component_dropdown_filter_priority),
      _createVNode(_component_dropdown_filter_radio, _mergeProps(_ctx.dueDate, { name: "Due date" }), null, 16)
    ]),
    _: 1
  }, 8, ["search"]))
}