
import { defineComponent, ref } from 'vue'
import type { TableHeaders } from '@/definitions/shared/types'
import TasksFilter from '@/components/pages/tasks/index/TasksFilter.vue'
import TasksTable from '@/components/pages/tasks/index/TasksTable.vue'
import { tasksMock } from '@/definitions/tasks/data'

export default defineComponent({
  components: {
    TasksFilter,
    TasksTable,
  },
  setup() {
    const selected = ref()

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'ID', value: 'id' },
      { text: 'P', value: 'priority', width: '32px' },
      { text: 'Title', value: 'title' },
      { text: 'Stage', value: 'stage' },
      { text: 'Assignee', value: 'assignee' },
      { text: 'Due date', value: 'due' },
      { text: 'Last updated', value: 'lastUpdated' },
    ])
    const tableItems = tasksMock

    return {
      tableItems,
      selected,
      tableHeaders,
    }
  },
})
