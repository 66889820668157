
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import useTasks from '@/compositions/tasks/useTasks'

export default defineComponent({
  components: {
    TmTableActionButton,
    TmDropdown,
    TmDropdownItem,
  },
  setup() {
    const { openDeleteTask } = useTasks()

    return {
      openDeleteTask,
    }
  },
})
