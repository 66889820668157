
import { defineComponent, ref } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import { useModals } from '@/compositions/modals'
import { useBreakpoints } from '@/compositions/breakpoints'
import DropdownFilterPriority from '@/components/shared/filters/dropdown/DropdownFilterPriority.vue'
import DropdownFilterRadio from '@/components/shared/filters/radio/DropdownFilterRadio.vue'
import DropdownFilterAssignee from '@/components/shared/filters/dropdown/DropdownFilterAssignee.vue'
import { assigneeOptions } from '@/definitions/_general/_data/optionsList'
import { dueDate } from '@/definitions/shared/filters/filtersLib/data'
import TmButton from '@/components/shared/TmButton.vue'
import TmToggleButtons from '@/components/shared/TmToggleButtons.vue'
import useTasks from '@/compositions/tasks/useTasks'

export default defineComponent({
  components: {
    TmButton,
    TmToggleButtons,
    DropdownFilterRadio,
    DropdownFilterAssignee,
    DropdownFilterPriority,
    TopFilter,
    TopFilterButton,
  },
  setup() {
    const search = ref('')
    const { isXXlMax } = useBreakpoints()
    const { openModal } = useModals()
    const { openDeleteTasks } = useTasks()

    return {
      search,
      openDeleteTasks,
      dueDate,
      isXXlMax,
      assigneeOptions,
      openModal,
    }
  },
})
